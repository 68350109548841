import React from 'react'
import { Link } from 'react-router-dom'
import MaterialTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import './Table.scss';

const Table = ({headers, data, onEdit, onDelete}) => {
  const hasActions = onEdit || onDelete;

  const renderCell = (entry, header) => {

    switch (header) {

      case 'bottle': {
        return (
          <Link to='/admin/bottles'>
            {entry[header].length ? entry[header][0]['title'] : entry[header]}
          </Link>
        );
      }

      case 'account': {
        return (
          <Link to='/admin/accounts'>
            {entry[header].length ? entry[header][0]['title'] : entry[header]}
          </Link>
        );
      }

      case 'qrCode': {
        return (<img src={entry[header]} height="50" alt="QR Code" />);
      }

      case 'createdAt': {
        return (entry[header]);// TODO: Format to relative time (x time ago)
      }

      default: {
        return entry[header] !== undefined ?
               entry[header].toLocaleString() :
               entry[header];
      }
    }

  };

  return (
    <MaterialTable component="div" className="Table">
      <TableHead component="div" className="Table__head">
        <TableRow component="div">
        {
          headers && headers.map((header, i) =>
            <TableCell component="div" key={i}>
              {header}
            </TableCell>
        )}
        {
          hasActions &&
          <TableCell component="div">
            Actions
          </TableCell>
        }
        </TableRow>
      </TableHead>
      <TableBody component="div">
        {
          data && data.map((entry, entryIndex) => (
            <TableRow component="div" key={entryIndex}>
              {
                headers.map((header, headerIndex) => (
                  <TableCell component="div" key={headerIndex}>
                    {
                      renderCell(entry, header)
                      // header === "bottle" && entry[header].length ? (
                      //   <Link
                      //     to='/admin/bottles'>
                      //     {entry[header][0]["title"]}
                      //   </Link>
                      // ) : (
                      //   header === "qrCode" ? (
                      //     <img src={entry[header]} height="50" alt="QR Code" />
                      //   ) : (
                      //     header === "account" && entry[header].length ? (
                      //       <Link
                      //         to='/admin/accounts'>
                      //         {entry[header][0]["title"]}
                      //       </Link>
                      //     ) : (
                      //       entry[header] !== undefined ? entry[header].toLocaleString() : entry[header]
                      //     )
                      //   )
                      // )
                    }
                  </TableCell>
                )
              )}
              {
                hasActions &&
                <TableCell component="div">
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    className="btn"
                    onClick={() => onEdit(entry)}>
                      Edit
                  </Button>
                  <Button
                    size="small"
                    color="inherit"
                    variant="outlined"
                    className="btn"
                    onClick={() => onDelete(entry)}>
                      Delete
                  </Button>
                </TableCell>
              }
            </TableRow>
          )
        )}
      </TableBody>
    </MaterialTable>
  )
}

export default Table
