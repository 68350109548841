import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Ribbon from '../ribbon/Ribbon'
import {AppBar,Toolbar,IconButton,Popper,Paper,Fade} from '@material-ui/core'
import CrownIcon from '../icons/CrownIcon'
import './Header.scss'

const Header = ({branding, points}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;
  const brand = {
    logo: branding && branding.logo ? branding.logo : '',
    colourBg: branding && branding.colourBg ? branding.colourBg : '',
    colourFg: branding && branding.colourFg ? branding.colourFg : '',
    title: branding && branding.title ? branding.title : '',
  }

  return (
    <>
      <AppBar className={`Header ${brand && brand.title ? 'Header--with-brand' : ''}`}
              style={{backgroundColor: brand.colourBg}}
              position="static">
        <Toolbar style={{backgroundColor: brand.colourBg}}>

          <IconButton
            edge="start"
            color="inherit"
            aria-describedby={id}
            onClick={handleClick}>
            <CrownIcon className="Header__icon" colour={brand.colourFg} />
          </IconButton>

          <div className="Header__logo"
            style={{backgroundImage: `url(${brand.logo})`}} />

          <div className="Header__points" style={{color: brand.colourFg}}>
            {points} pts
          </div>

        </Toolbar>

        {
          (brand && brand.title) && (
            <div className="Header__title">
              {brand.title}
            </div>
          )
        }

        {
          (!brand || !brand.title) && (
            <Ribbon />
          )
        }

      </AppBar>

      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Link to="/user">
                Log Out
              </Link>
            </Paper>
          </Fade>
        )}
      </Popper>

    </>
  )
}

export default Header
