import { createMuiTheme } from '@material-ui/core/styles'
export const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00',
      }
    },
    props: {
      MuiButtonBase: {
        disableRipple: true
      },
    },
    overrides: {
      MuiContainer: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: "white",
          boxShadow: "none",
          color: "black"
        }
      },
      MuiButton: {
        root: {
          borderRadius: 3,
          border: 0,
          backgroundColor: "white",
          height: 48,
          width: "100%",
          fontSize: 18,
          padding: "0 30px",
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .30)"
        },
        label: {
          color: "black",
        }
      }
    }
  })
