import React, { Component } from 'react'
import Copy from '../../common/Copy'
import History from '../../common/History'
import { Grid, Button } from '@material-ui/core'
import Logo from '../../components/logo/Logo'
import Ribbon from '../../components/ribbon/Ribbon'

class Login extends Component {

    componentDidMount() {
      document.body.classList.add("grey-bg")
    }

    handleLogin = () => {
      History.push("/user/scan")
    }

    render() {
        return(
            <Grid
                container
                className="Container">

                <section className="login-header">
                  <Logo />
                </section>

                <section className="login-cover">
                  <div className="login-details center-text">
                    <h3 className="white-text">
                      {Copy.loginPage.message}
                    </h3>
                    <Button
                      variant="contained"
                      className="Button"
                      onClick={this.handleLogin}>
                      {Copy.loginPage.btnLabel}
                    </Button>
                  </div>
                </section>

                <Ribbon />

            </Grid>
        )
    }
}


export default Login
