import React from 'react'

const CrownIcon = props => (
  <svg viewBox="0 0 94.08 94.08" width="1em" height="1em">
    <g fill={props.colour ? props.colour : '#b51c1e'}>
      <path d="M47.22 14.76a32 32 0 1032 32 32 32 0 00-32-32zm4 32a4 4 0 10-8 0h-4a8 8 0 1116 0zm14 0a18 18 0 10-36 0h-4a22 22 0 1144 0z" />
      <path d="M93.18 42.35L94 38.3l-2.26-3.47a26.83 26.83 0 01-3.9-9.78L87.12 21l-3.41-2.34a27 27 0 01-7.36-7.54L74.1 7.63l-4-.86a27.15 27.15 0 01-9.68-4.15L57 .27 52.88 1A27 27 0 0142.35.9L38.3 0l-3.47 2.3a27 27 0 01-9.78 3.91L21 7l-2.34 3.41a27 27 0 01-7.54 7.36L7.63 20l-.86 4a27.28 27.28 0 01-4.15 9.69L.27 37.13 1 41.2a27 27 0 01-.1 10.53L0 55.78l2.3 3.47A27.1 27.1 0 016.21 69L7 73.1l3.41 2.34A27 27 0 0117.73 83L20 86.45l4 .86a27.12 27.12 0 019.69 4.16l3.41 2.34 4.07-.76a27 27 0 0110.53.13l4.05.86 3.47-2.26a26.9 26.9 0 019.78-3.9l4.07-.76 2.34-3.41A27 27 0 0183 76.35l3.47-2.25.86-4a27 27 0 014.16-9.68L93.81 57l-.76-4.07a27 27 0 01.13-10.58zm-46 40.35a36 36 0 1136-36 36 36 0 01-35.96 36z" />
    </g>
  </svg>
)
export default CrownIcon
