import React, { Component, createRef } from 'react';
import { Grid } from '@material-ui/core';
import jsQR from 'jsqr';
import { debounce } from 'throttle-debounce';
import 'url-search-params-polyfill';
import Config from '../../common/Config';


class Camera extends Component {

  constructor(props) {
    super(props);

    this.video = createRef();
    this.canvas = null;//createRef();
    this.ctx = null;

    this.camWidth = 400;
    this.camHeight = 600;
    this.cameraRefresh = 250;
    this.cameraStream = null;

    this.INITIAL_STATE = {
      qrData: {data: null},
      bottle: null,
      crown: null,
      result: null
    }

    this.state = this.INITIAL_STATE;

    this.allowScanTimeout = null;

    // Debounce scan handler to prevent multiple consecutive submissions
    this.handleScanData = debounce(500, true, this.handleScanData);
  }

  componentDidMount() {
    if (this.checkHasCamera()) {

      this.canvas = document.createElement('canvas');
      this.canvas.width = this.camWidth;
      this.canvas.height = this.camHeight;
      this.ctx = this.canvas.getContext('2d');

      this.startCamera();
    }
  }

  componentWillUnmount() {
    this.stopCamera();
  }

  checkHasCamera = () => {
    // Get access to the camera!
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
  };

  startCamera = () => {
    navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        }
      })
      .then(stream => {
        this.cameraStream = stream;
        this.video.current.srcObject = this.cameraStream;
        setInterval(this.handleCameraStream, this.cameraRefresh);
      });
  };

  stopCamera = () => {
    // Disconnect camera stream
    if (this.cameraStream && this.cameraStream.active) {
      const track = this.cameraStream.getTracks()[0];
      if (track) {
        track.stop();
      }
    }
  }

  handleCameraStream = () => {
    if (this.video.current) {
      this.ctx.drawImage(this.video.current, 0, 0);

      const imgData = this.ctx.getImageData(0, 0, this.camWidth, this.camHeight);
      const qrData = jsQR(
        imgData.data,
        this.camWidth,
        this.camHeight,
        {inversionAttempts: 'dontInvert'}
      );

      this.handleQrData(qrData);
    }
  };

  handleQrData = qrData => {
    // Check if QR data format is valid
    if (!qrData || !qrData.data ) {
      return;
    }

    // Check if scanned QR code is not the same as last one
    if (this.state.qrData && this.state.qrData.data && this.state.qrData.data === qrData.data) {
      console.log('Same QR code as last check. Ignoring…');
      if (this.state.result) {
        this.showResult(this.state.result);
      }
      return;
    }

    // Parse and unpack data/url
    const url = qrData.data;
    const qs = url.split('?')[1];
    const params = new URLSearchParams(qs);

    // Go and find the bottle from QR Code
    const sku = params.get('sku');
    const uid = params.get('uid');
    const bottle = this.props.bottles.find(item => {
      return item.sku === sku;
    });

    // Exit if no bottle matched the QR
    if (!bottle) {
      this.showResult('invalid');
      this.resetState();
      this.props.handleUid(null);
      this.props.handleBottle(null);
      return;
    }

    this.props.handleUid(uid);
    this.props.handleBottle(bottle);
    this.props.handleChangeToastVariant(null);
    this.setState({
      bottle,
      qrData
    });

    // Query database to see status of QR code
    this.fetchCrown(uid);
  };

  handleError = (err) => {
    console.error(err);
  };

  getRequestOptions = options => {
    return {
      ...options,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  resetState = () => {
    this.setState(this.INITIAL_STATE);
  }

  allowScan = () => {
    this.setState({
      qrData: {data: null}
    })
  }

  showResult = result => {
    if (!result) return;
    this.setState({result});
    this.props.handleChangeToastVariant(result);

    clearTimeout(this.allowScanTimeout);
    this.allowScanTimeout = setTimeout(this.allowScan, 5000);
  }

  fetchCrown = (uid) => {
    fetch(`${Config.apiEndpoint}/crowns/uid/${uid}`,
      this.getRequestOptions()
    )
    .then(res => {
      res.json().then(data => {
        // If QR code is valid and unopened look for cap
        if (data.status === 'ok') {
          // Crown is found so set next steps
          if (!data.crown.redeemable) {
            // Crown is already redeemed
            this.showResult('error');
          } else {
            if (!data.crown.closed) {
              // Crown is already open
              this.showResult('taken');
            } else {
              // Valid crown so proceed to check cap status
              this.validateBottle();
            }
          }
          this.props.handleCrown(data.crown);
        } else {
          this.showResult('invalid');
        }
      },
      error => {
        // TODO
      })
    });
  };

  validateBottle = () => {
    const bytes = this.canvas.toDataURL(
      'image/jpeg',
      0.5
    ).replace('data:image/jpeg;base64,', '');

    const data = {
      payload: {
        image: {
          imageBytes: bytes
        }
      }
    };

    // Request a different AutoML model to validate against
    if (this.state.bottle && this.state.bottle.automlModel) {
      data.model = this.state.bottle.automlModel;
    }

    fetch(`${Config.apiEndpoint}/bottles/validate`,
      this.getRequestOptions({
        method: 'POST',
        body: JSON.stringify(data)
      })
    ).then(res => {

      res.json().then(data => {
        if (data && data.status === 'ok') {
          if (data.crown === 'off') {
            this.showResult('success');
          } else if (data.crown === 'on') {
            this.showResult('closed');
          } else {
            console.log('Crown is MIA?', data.message);
          }
        }
      });
    });
  };

  disableHeaderIfNeeded = (className) => {
    return process.env.REACT_APP_DISABLE_HEADER ? ` ${className}--no-header` : '';
  }

  enableBrandHeader = (className, bottle) => {
    return bottle && bottle.title ? ` ${className}--with-brand-header` : '';
  }

  getOutlineImage = (bottle) => {
    return bottle && bottle.outline ? `url(${bottle.outline})` : 'none';
  }

  render() {
    return (
      <Grid id="CameraContainer" container className="camera_container">
        <div className={
          `overlay` +
          this.enableBrandHeader('overlay', this.state.bottle) +
          this.disableHeaderIfNeeded('overlay')
        } style={{
          backgroundImage: this.getOutlineImage(this.state.bottle)
        }} />
        <video
          className={`camera` + this.disableHeaderIfNeeded('camera')}
          ref={this.video}
          muted
          playsInline
          autoPlay />
      </Grid>
    );
  }
}

export default Camera;
