import React, { forwardRef, useRef, useState } from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'

import Config from '../../common/Config';
import ColourSwatch from '../icons/ColourSwatch'

import './BottleForm.scss'

const BottleForm = forwardRef(({bottle, accounts, onSubmit, onChange, onClose}, ref) => {

  const isEditing = bottle && bottle.createdAt ? true : false;
  const logoRef = useRef();
  const outlineRef = useRef();
  const [logoLoading, setLogoLoading] = useState(false);
  const [outlineLoading, setOutlineLoading] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [outlineLoaded, setOutlineLoaded] = useState(false);
  let logoLoadedTimeout = null;
  let outlineLoadedTimeout = null;
  const bottleAccount = bottle &&
                        bottle.account &&
                        bottle.account.length ?
                        bottle.account[0]._id : '';

  const checkSKU = event => {
    if (!bottle || !bottle.sku) {
      event.preventDefault();
      window && window.alert('Please fill the SKU first');
      document.getElementById('bottle-sku').focus();
      return;
    }
  }

  const setReactInputValue = (input, value) => {
    const previousValue = input.value;
    input.value = value;

    const tracker = input._valueTracker;
    if (tracker) {
      tracker.setValue(previousValue);
    }

    input.dispatchEvent(new Event('change', { bubbles: true }));
  }

  const handleImageUpload = async event => {

    // Confirm if a file was selected
    if (!event.target.files[0]) {
      return;
    }

    // Prepare form data
    const imageType = event.target.name;
    const data = new FormData();
    data.append('sku', bottle.sku);
    data.append('file', event.target.files[0]);

    // Enable loading state
    if (imageType === 'outline') { setOutlineLoading(true); }
    if (imageType === 'logo') { setLogoLoading(true); }

    // Fetch image
    fetch(`${Config.apiEndpoint}/bottles/upload/${imageType}`,
      {
        method: 'POST',
        body: data
      }
    ).then(res => {
      res.json().then(data => {
        if (data.status === 'ok') {

          if (imageType === 'outline' && outlineRef.current) {
            // Update textfield with image url
            outlineRef.current.focus();
            setReactInputValue(outlineRef.current, data.url);

            // Update loading states
            setOutlineLoading(false);
            setOutlineLoaded(true);

            // Clear loaded state
            clearTimeout(outlineLoadedTimeout);
            outlineLoadedTimeout = setTimeout(() => {
              setOutlineLoaded(false);
            }, 3000);
          }

          if (imageType === 'logo' && logoRef.current) {
            // Update textfield with image url
            logoRef.current.focus();
            setReactInputValue(logoRef.current, data.url);

            // Update loading states
            setLogoLoading(false);
            setLogoLoaded(true);

            // Clear loaded state
            clearTimeout(logoLoadedTimeout);
            logoLoadedTimeout = setTimeout(() => {
              setLogoLoaded(false);
            }, 3000);
          }

        } else {
          // Clear loading states
          setOutlineLoading(false);
          setLogoLoading(false);

          // Inform user
          window && window.alert('Could not upload file. Error: '+ data.err);
        }
      },
      error => {
        // Clear loading states
        setOutlineLoading(false);
        setLogoLoading(false);

        // Inform user
        window && window.alert('Could not upload file. Error: '+ error);
      })
    });
  }

  return (
    <Box
      ref={ref}
      className="Fullscreen BottleForm"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Container maxWidth="md">
        <Paper className="BottleForm__panel">

          <Typography variant="h4">
            { isEditing ? 'Edit bottle' : 'Add new bottle' }
          </Typography>

          <IconButton
            className="BottleForm__close"
            onClick={onClose}>
            <Tooltip title="Cancel">
              <CloseIcon />
            </Tooltip>
          </IconButton>


          <form onSubmit={onSubmit} autoComplete="off">

            <Grid container spacing={2}>

              <Grid item xs={6}>
                <TextField
                  className="BottleForm__select"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="off"
                  select
                  label="Account"
                  name="account"
                  onChange={onChange}
                  defaultValue={bottleAccount}>
                  {
                    accounts.map( (acc, i) => {

                      const isSelected = () => {
                        return bottle &&
                               bottle.account &&
                               bottle.account.length ?
                               bottle.account[0]._id === acc._id : false;
                      }

                      return (
                        <MenuItem
                          key={i}
                          selected={isSelected()}
                          value={acc._id}>
                          {acc.title}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoFocus
                  autoComplete="off"
                  type="text"
                  label="Title"
                  name="title"
                  onChange={onChange}
                  placeholder="AB Inbev bottle 330ml"
                  value={bottle.title || ''} />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="off"
                  type="text"
                  label="SKU"
                  name="sku"
                  helperText="Max length: 2 characters"
                  onChange={onChange}
                  placeholder="AB"
                  value={bottle.sku || ''}
                  inputProps={{
                    id: 'bottle-sku',
                    maxLength: 2
                  }} />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="off"
                  type="url"
                  label="Redirect URL"
                  name="redirectUri"
                  onChange={onChange}
                  placeholder="http://..."
                  value={bottle.redirectUri || ''} />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="AutoML model"
                  name="automlModel"
                  onChange={onChange}
                  placeholder="ICN1639789763969971452"
                  value={bottle.automlModel || ''} />

              </Grid>

              <Grid item xs={6}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  type="url"
                  label="Logo"
                  name="logo"
                  onChange={onChange}
                  placeholder="https://.."
                  value={bottle.logo || ''}
                  inputRef={logoRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="progress-wrapper">
                          <IconButton
                            edge="end"
                            component="label"
                            disabled={logoLoading}
                            onClick={checkSKU}>
                            {
                              logoLoaded ? <CheckIcon /> : (
                                <Tooltip title="Upload new logo">
                                  <CloudUploadIcon />
                                </Tooltip>
                              )
                            }
                            <input
                              name="logo"
                              type="file"
                              accept="image/png"
                              onChange={handleImageUpload}
                              hidden />
                          </IconButton>
                          {
                            logoLoading &&
                            <CircularProgress size={40} className="circular-progress" />
                          }
                        </div>
                        {
                          bottle.logo && (
                            <IconButton
                              edge="end"
                              disabled={logoLoading}
                              onClick={() => { window.open(bottle.logo); }}>
                              <Tooltip title="View logo">
                                <VisibilityIcon />
                              </Tooltip>
                            </IconButton>
                          )
                        }
                      </InputAdornment>
                    )
                  }} />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  type="url"
                  label="Outline"
                  name="outline"
                  onChange={onChange}
                  placeholder="https://.."
                  value={bottle.outline || ''}
                  inputRef={outlineRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="progress-wrapper">
                          <IconButton
                            edge="end"
                            component="label"
                            disabled={outlineLoading}
                            onClick={checkSKU}>
                            {
                              outlineLoaded ? <CheckIcon /> : (
                                <Tooltip title="Upload new outline">
                                  <CloudUploadIcon />
                                </Tooltip>
                              )
                            }
                            <input
                              name="outline"
                              type="file"
                              accept="image/png"
                              onChange={handleImageUpload}
                              hidden />
                          </IconButton>
                          {
                            outlineLoading &&
                            <CircularProgress size={40} className="circular-progress" />
                          }
                        </div>
                        {
                          bottle.outline && (
                            <IconButton
                              edge="end"
                              disabled={outlineLoading}
                              onClick={() => { window.open(bottle.outline); }}>
                              <Tooltip title="View outline">
                                <VisibilityIcon />
                              </Tooltip>
                            </IconButton>
                          )
                        }
                      </InputAdornment>
                    )
                  }} />

                <Grid container spacing={2}>

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      autoComplete="off"
                      type="text"
                      label="Background colour"
                      name="colourBg"
                      helperText="Used as header background"
                      onChange={onChange}
                      placeholder="#FFFFFF"
                      value={bottle.colourBg || ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ColourSwatch colour={bottle.colourBg} />
                          </InputAdornment>
                        )
                      }} />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      autoComplete="off"
                      type="text"
                      label="Foreground colour"
                      name="colourFg"
                      helperText="Used as font and icon colour"
                      onChange={onChange}
                      placeholder="#000000"
                      value={bottle.colourFg || ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ColourSwatch colour={bottle.colourFg} />
                          </InputAdornment>
                        )
                      }} />
                  </Grid>

                </Grid>

                <TextField
                  className="BottleForm__select"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="off"
                  select
                  label="Toast position"
                  name="toastPosition"
                  onChange={onChange}
                  value={bottle.toastPosition || 'bottom'}>
                  <MenuItem
                    selected={bottle.toastPosition === 'bottom' ? true : false}
                    value="bottom">
                    Bottom
                  </MenuItem>
                  <MenuItem
                    selected={bottle.toastPosition === 'middle' ? true : false}
                    value="middle">
                    Middle
                  </MenuItem>
                </TextField>

              </Grid>

            </Grid>

            <Button
              className="BottleForm__submit"
              type="submit"
              variant="outlined"
              color="primary">
              Save
            </Button>
          </form>


        </Paper>
      </Container>
    </Box>
  )
})


export default BottleForm
