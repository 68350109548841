import React from 'react'
import { Grid, Button } from '@material-ui/core'

const CameraAccess = ({copy,handleRequestCamera}) => (
  <Grid
    container
    alignContent="center"
    alignItems="center"
    justify="center"
    className="camera-access"
    >
    <div className="camera-intro center-text">
      <Button
        onClick={handleRequestCamera}
        variant="contained">
        {copy.btnLabel}
      </Button>
      <h3 className="white-text">
        {copy.enable}
      </h3>
    </div>
  </Grid>
)

export default CameraAccess
