module.exports = {
  loginPage: {
    message: "Hey, bartender! Log in to earn points in our exclusive on-trade experience",
    btnLabel: "Log In"
  },
  userScan: {
    enable: "You must allow camera permissions to scan bottles",
    btnLabel: "Allow camera access",
    successBtnLabel: "Redeem points",
    toast: {
      begin: "Look for the QR code on the bottle neck and scan to start earning points",
      missing: "Align the QR code to the box highlighted",
      taken: "Hmmm. This bottle has already been recorded as open. Try another",
      invalid: "Hmmm. This bottle code was not recognised",
      error: "Hmmm. This bottle has already been scanned",
      closed: "Open the bottle before scanning to earn points",
      success: "That's an open bottle. Click to redeem your points!",
      redeemed: "Congrats, your points were redeemed!"
    }
  }
}
