import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import CloseIcon from '@material-ui/icons/Close'

import Config from '../../common/Config'
import Loading from '../../components/loading/Loading'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import CrownForm from '../../components/crownForm/CrownForm'
import Table from '../../components/table/Table'
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary'
import './AdminList.scss';


class Crowns extends Component {

    state = {
      token: localStorage.getItem('auth_token') || null,
      isLoaded: false,
      isError: false,
      isModalOpen: false,
      headers: [
        "account",
        "uid",
        "closed",
        "redeemable",
        "bottle",
        "campaignId",
        "redirectUri",
        "qrCode"
      ],
      crowns: [],
      bottles: [],
      accounts: [],
      crown: {},
      isSnackbarOpen: false,
      snackBarMessage: "",
      iterations: 1,

      itemsLimit: 20,
      itemsTotal: null,
      pagesTotal: null,
      prevPage: null,
      nextPage: null,
      currentPage: 1,

      filters: {
        open: true,
        closed: true,
        redeemed: true,
        redeemable: true
      },

      counts: {
        open: null,
        closed: null,
        redeemed: null,
        redeemable: null
      }
    }

    componentDidMount() {
      this.handleFetchCrowns()
      this.handleFetchBottles()
      this.handleFetchAccounts()
      this.handleFetchCounts()
    }

    handleFetchCrowns = (page = this.state.currentPage, filters = this.state.filters) => {

      // Build filter params to append to url
      const searchFilter = [];
      Object.entries(filters).forEach( ([key, value]) => {
        if (value === false) {
          searchFilter.push(`${key}=0`)
        }
      });

      fetch(`${Config.apiEndpoint}/crowns?limit=${this.state.itemsLimit}&page=${page}${
        searchFilter.length > 0 ? '&'+searchFilter.join('&') : ''
      }`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => res.json())
      .then(
        (data) => {

          this.setState({
            isLoaded: true,
            isError: false,
            crowns: data.items,
            itemsTotal: data.total,
            pagesTotal: data.totalPages,
            prevPage: data.prevPage,
            nextPage: data.nextPage,
            currentPage: data.page
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            isError: true
          })
        }
      )
    }

    handleFetchCounts = () => {
      fetch(`${Config.apiEndpoint}/crowns/counts`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => res.json())
      .then(
        (data) => {

          if (data && data.status && data.status === 'ok') {
            this.setState({
              counts: data.counts
            });
          }
        },
        (error) => {}
      )
    }

    handleFetchBottles = () => {
      fetch(`${Config.apiEndpoint}/bottles`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.setState({
            bottles: data.items
          })
        }
      )
    }

    handleFetchAccounts = () => {
      fetch(`${Config.apiEndpoint}/accounts`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.setState({
            accounts: data.items
          })
        },
        (error) => {
        }
      )
    }

    handleOpenModal = (crown) => {
      this.setState({
        isModalOpen: true,
        crown: crown
      })
    }

    handleCloseModal = () => {
      this.setState({
        isModalOpen: false
      })
    }

    handleOnSubmit = (e) => {
      e.preventDefault()

      let newCrown,endpoint,method
      newCrown = this.state.crown

      // Create a new entry or update existing
      if (newCrown._id === undefined) {
        endpoint = `${Config.apiEndpoint}/crowns`
        method = "POST"
      } else {
        endpoint = `${Config.apiEndpoint}/crowns/${newCrown._id}`
        method = "PUT"
      }

      fetch(endpoint, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.token}`
        },
        body: JSON.stringify({crown: this.state.crown, iterations: this.state.iterations})
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.handleCloseModal()
          if (data.message === "Crown created successfully" || data.message === "Crown updated") {
            const {crowns} = {...this.state}
            crowns.unshift(data.crown)
            this.setState({
              crowns: crowns,
              itemsTotal: data.message.indexOf('created') !== -1 ? this.state.itemsTotal + 1 : this.state.itemsTotal,
              isSnackbarOpen: true,
              snackBarMessage: "Crown saved"
            })
          }
        },
        (error) => {
          this.setState({
            isError: true
          })
        }
      )
    }

    handleOnChange = (e) => {
      const target = e.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name
      const {crown} = {...this.state}
      let {iterations} = {...this.state}
      if (name === "iterations") {
        iterations = value
      } else {
        crown[name] = value
      }
      this.setState({
        crown,
        iterations
      })
    }

    handleOnDelete = (crown) => {
      const {crowns} = {...this.state}
      fetch(`${Config.apiEndpoint}/crowns/${crown._id}`, {
        method: "DELETE",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          if (data.message === "Crown deleted") {
            crowns.splice(crowns.indexOf(crown),1)
            this.setState({
              crowns: crowns,
              itemsTotal: this.state.itemsTotal - 1,
              isSnackbarOpen: true,
              snackBarMessage: "Crown deleted"
            })
          }
        },
        (error) => {
          this.setState({
            isError: true
          })
        }
      )
    }

    handleDownloadQrCodes = () => {
      fetch(`${Config.apiEndpoint}/crowns/download`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          if (data.message === "QR codes downloaded") {
            this.setState({
              isSnackbarOpen: true,
              snackBarMessage: "QR codes saved to S3"
            })
          }
        },
        (error) => {}
      )
    }

    handleCloseSnackbar = () => {
      this.setState({
        isSnackbarOpen: false,
        snackBarMessage: ""
      })
    }

    handleFilterChange = (event) => {
      const filters = {...this.state.filters, [event.target.value]: event.target.checked};
      this.setState({...this.state, filters});

      this.handleFetchCrowns(1, filters);
    }

    renderPagination = () => {

      const goToPage = (page) => {
        this.setState({currentPage: page});
        this.handleFetchCrowns(page);
        window && window.scrollTo(0, 0);
      }
      const goToFirstPage = () => goToPage(1);
      const goToLastPage = () => goToPage(this.state.pagesTotal);
      const goToPreviousPage = () => goToPage(this.state.currentPage - 1);
      const goToNextPage = () => goToPage(this.state.currentPage + 1);
      const isFirstPage = this.state.currentPage === 1;
      const isLastPage = this.state.currentPage === this.state.pagesTotal;
      const shouldRender = this.state.pagesTotal > 1;

      return (
        shouldRender &&
        <div className="AdminList__pagination">
          <ButtonGroup variant="contained">
            <Button disabled={isFirstPage} onClick={goToFirstPage}>First page</Button>
            <Button disabled={isFirstPage} onClick={goToPreviousPage}>Previous page</Button>
            <Button disabled className="AdminList__pagination-count">Page {this.state.currentPage} of {this.state.pagesTotal}</Button>
            <Button disabled={isLastPage} onClick={goToNextPage}>Next page</Button>
            <Button disabled={isLastPage} onClick={goToLastPage}>Last page</Button>
          </ButtonGroup>
        </div>
      );
    }

    render() {

      const {
        isLoaded,
        crowns,
        accounts,
        bottles,
        headers,
        isModalOpen,
        crown,
        isSnackbarOpen,
        snackBarMessage,
        itemsTotal,
        filters,
        counts
      } = this.state

      return (
        <>
          <AdminHeader />
          <div className="AdminList white-bg">
            <>
              <section className="AdminList__header page-header">
                <h1 className="AdminList__title">Crowns ({itemsTotal !== null ? itemsTotal : '…'})</h1>
                <div className="AdminList__filters">
                  <FormGroup row>
                    <FormGroup row className="AdminList__filter-group">
                      <FormControlLabel
                        label={`Open (${counts.open !== null ? counts.open : '…'})`}
                        title={+(counts.open * 100 / itemsTotal).toFixed(2) +'%'}
                        control={
                          <Checkbox
                            value="open"
                            checked={filters.open}
                            onChange={this.handleFilterChange} />
                        } />
                      <FormControlLabel
                        label={`Closed (${counts.closed !== null ? counts.closed : '…'})`}
                        title={+(counts.closed * 100 / itemsTotal).toFixed(2) +'%'}
                        control={
                          <Checkbox
                            value="closed"
                            checked={filters.closed}
                            onChange={this.handleFilterChange} />
                        } />
                    </FormGroup>
                    <FormGroup row className="AdminList__filter-group">
                      <FormControlLabel
                        label={`Redeemed (${counts.redeemed !== null ? counts.redeemed : '…'})`}
                        title={+(counts.redeemed * 100 / itemsTotal).toFixed(2) +'%'}
                        control={
                          <Checkbox
                            value="redeemed"
                            checked={filters.redeemed}
                            onChange={this.handleFilterChange} />
                        } />
                      <FormControlLabel
                        label={`Redeemable (${counts.redeemable !== null ? counts.redeemable : '…'})`}
                        title={+(counts.redeemable * 100 / itemsTotal).toFixed(2) +'%'}
                        control={
                          <Checkbox
                            value="redeemable"
                            checked={filters.redeemable}
                            onChange={this.handleFilterChange} />
                        } />
                    </FormGroup>
                  </FormGroup>
                </div>
                <div className="AdminList__actions">
                  <Button
                    onClick={() => this.handleOpenModal({})}
                    variant="outlined"
                    className="btn"
                    color="primary">
                    New Crown
                  </Button>
                  <Button
                    onClick={() => this.handleDownloadQrCodes()}
                    variant="outlined"
                    className="btn"
                    color="primary">
                    Download
                  </Button>
                </div>
              </section>
              <ErrorBoundary>
              {
                crowns || isLoaded ? (
                  <>
                    <Paper>
                      <Table
                        headers={headers}
                        data={crowns}
                        onEdit={this.handleOpenModal.bind(this)}
                        onDelete={this.handleOnDelete.bind(this)} />
                    </Paper>

                    { this.renderPagination() }

                  </>
                ) : (
                  <Loading />
                )
              }
              </ErrorBoundary>
            </>
        </div>
          <Modal
            open={isModalOpen}
            onClose={this.handleCloseModal.bind(this)}
          >
            <CrownForm
              crown={crown}
              accounts={accounts}
              bottles={bottles}
              onClose={this.handleCloseModal.bind(this)}
              onSubmit={this.handleOnSubmit.bind(this)}
              onChange={this.handleOnChange.bind(this)} />
          </Modal>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isSnackbarOpen}
            onClose={this.handleCloseSnackbar.bind(this)}
            autoHideDuration={3000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={snackBarMessage}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleCloseSnackbar.bind(this)}
              >
              <CloseIcon />
            </IconButton>
          ]}
          />
        </>
      )

    }

}

export default Crowns
