import React, { useState } from 'react'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Route, Redirect } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import { theme } from './theme/Theme'

import Login from './pages/shared/Login'
import Home from './pages/public/Home'
import Scan from './pages/user/Scan'
import Accounts from './pages/admin/Accounts'
import Crowns from './pages/admin/Crowns'
import Bottles from './pages/admin/Bottles'
import Users from './pages/admin/Users'
import Logs from './pages/admin/Logs'
import AdminLogin from './pages/admin/AdminLogin'
import { useAuthState, AuthProvider } from './common/use-auth'


const AdminRoute = ({ component: Component, ...rest}) => {
  const {active} = useAuthState();

  return (
    <Route
      {...rest}
      render={ props =>
        active ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/admin/login',
            state: { referer: props.location}
          }} />
        )
      } />
  )
}

const App = () => {

  const [points, setPoints] = useState(300);

  const handleAddPoints = (val) => {
    setPoints(points + val);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <AnimatedSwitch
            atEnter={{ offset: 100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            className="route-wrapper"
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}>

          {/* Public routes */}
          <Route exact path="/" component={Home} />

          <AuthProvider>

            {/* User routes */}
            {
              !process.env.REACT_APP_DISABLE_USER &&
                <>
                  <Route exact path="/user" component={Login} />
                  <Route exact path="/user/scan" render={(props) => (
                    <Scan handleAddPoints={handleAddPoints}
                          points={points} />
                  )} />
                </>
            }

            {/* Admin routes */}
            {
              !process.env.REACT_APP_DISABLE_ADMIN &&
                <>
                  <AdminRoute exact path="/admin/accounts" component={Accounts} />
                  <AdminRoute exact path="/admin/crowns" component={Crowns} />
                  <AdminRoute exact path="/admin/bottles" component={Bottles} />
                  <AdminRoute exact path="/admin/users" component={Users} />
                  <AdminRoute exact path="/admin/logs" component={Logs} />
                  <AdminRoute exact path="/admin" component={Crowns} />
                  <Route exact path="/admin/login" component={AdminLogin} />
                </>
            }

          </AuthProvider>

          <Redirect to="/" />

        </AnimatedSwitch>
      </CssBaseline>
    </MuiThemeProvider>
  )
}

export default App
