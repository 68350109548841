import React from 'react';
import './ColourSwatch.scss';

const ColourSwatch = ({colour}) => (
  (colour && (
    <div
      className="ColourSwatch"
      style={{backgroundColor: colour}} />
  )) || null
)

export default ColourSwatch;
