import React, { forwardRef } from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close';
import './AccountForm.scss';

const AccountForm = forwardRef(({account, onSubmit, onChange, onClose}, ref) => {

  const isEditing = account && account.createdAt ? true : false;

  return (
    <Box
      ref={ref}
      className="Fullscreen AccountForm"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Container maxWidth="sm">
        <Paper className="AccountForm__panel">

          <Typography variant="h4">
            { isEditing ? 'Edit account' : 'Add new account' }
          </Typography>

          <IconButton
            className="AccountForm__close"
            onClick={onClose}>
            <Tooltip title="Cancel">
              <CloseIcon />
            </Tooltip>
          </IconButton>

          <form onSubmit={onSubmit} autoComplete="off">

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoFocus
              autoComplete="off"
              type="text"
              label="Title"
              name="title"
              onChange={onChange}
              placeholder="AB InBev"
              value={account.title || ''} />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoComplete="off"
              type="text"
              label="Slug"
              name="slug"
              onChange={onChange}
              placeholder="abinbev"
              value={account.slug || ''} />

            <Button
              className="AccountForm__submit"
              type="submit"
              variant="outlined"
              color="primary">
              Save
            </Button>

          </form>

        </Paper>
      </Container>
    </Box>
  )
});

export default AccountForm
